<template>
  <b-modal
    :key="altKey"
    v-model="isVisible"
    hide-footer
    centered
    size="lg"
    :title="$t('schedule.title')"
    @show="isMapVisible = false"
  >
    <div class="d-flex align-items-center float-right">
      <feather-icon
        v-if="isStaff"
        icon="Trash2Icon"
        size="20"
        class="text-primary"
        role="button"
        @click="isUnshareModalVisible = true"
      />
      <feather-icon
        v-if="canShare"
        icon="Share2Icon"
        size="20"
        class="text-primary d-none d-lg-block ml-2"
        @click="isShareModalVisible = true"
      />
      <b-dropdown
        v-if="isStaff"
        event.key="dropdown-dropleft"
        variant="link"
        left
        class="mr-n2"
        no-caret
      >
        <template #button-content>
          <feather-icon
            v-if="isStaff"
            icon="SettingsIcon"
            size="20"
            class="text-primary"
          />
        </template>
        <b-dropdown-text class="bg-light">
          <p class="user-name font-weight-bolder mb-0">
            {{ $t("edit-dropdown.title") }}
          </p>
        </b-dropdown-text>
        <b-dropdown-item @click="isEditModalVisible = true">
          {{ $t("backoffice.settings.subtitle") }}
        </b-dropdown-item>
        <b-dropdown-item @click="isEditContactModalVisible = true">
          {{ $t("edit-dropdown.responsible") }}
        </b-dropdown-item>
        <b-dropdown-item
          class="d-block d-lg-none"
          @click="isShareModalVisible = true"
        >
          {{ $t("share.tooltip") }}
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <b-row>
      <b-col class="mt-1">
        <h4 class="event-title font-weight-bold pb-1">
          {{ translatedName }}
        </h4>

        <event-brief-information
          :event="event"
          :start-date="event.startDate"
          :end-date="event.endDate"
          :modality="selectedOption"
          :is-over="isOver"
          @adding-calendar="handleAddingToExternalCalendar"
          @see-map="isMapVisible = true"
        />
      </b-col>

      <b-col
        v-if="event.thumbnailURL"
        cols="12"
        lg="4"
        class="mb-1"
      >
        <safe-img
          :src="thumbnailSrc"
          :placeholder="EventPlaceholder"
          class="w-100 event-img"
        />
      </b-col>
    </b-row>

    <content-with-anchors
      class="ml-1 mt-1 mb-1 content-container"
      :content="translatedDescription"
    />

    <!-- Location map -->
    <div v-if="isMapVisible" id="locations-map" class="mt-1">
      <h5 class="font-weight-bolder mt-1">
        {{ $t("events.details.locations") }}
      </h5>
      <locations-map :item-key="event.key" type="event" class="locations-map" />
    </div>
    <add-to-external-calendar-modal
      v-model="isAddingToExternalCalendar"
      :event="event"
    />
    <b-modal
      id="modal-edit-event-2"
      v-model="isEditContactModalVisible"
      :title="$t('events.actions.edit-event')"
      :ok-title="$t('form.actions.save')"
      :cancel-title="$t('form-create-item.cancel')"
      cancel-variant="outline-secondary"
      centered
      size="lg"
      :hide-footer="true"
      :no-close-on-backdrop="true"
    >
      <contact
        :item="event"
        type="agenda"
        type-single="agenda"
        @handleEditItem="editEvent"
      />
    </b-modal>
    <b-modal
      id="modal-edit-event"
      v-model="isEditModalVisible"
      :title="$t('events.actions.edit-event')"
      :ok-title="$t('form.actions.save')"
      :cancel-title="$t('form-create-item.cancel')"
      cancel-variant="outline-secondary"
      centered
      size="lg"
      @ok="editEvent()"
    >
      <b-form>
        <b-form-group>
          <label for="name">{{ $t("form-create-item.title") }}:</label>
          <b-form-input id="name" v-model="event.title.en" type="text" />
        </b-form-group>
        <b-form-group>
          <label
            for="modality"
          >{{ $t("events.actions.modality-options") }}:</label>
          <v-select
            id="modality"
            v-model="selectedOption"
            :options="typeOptions"
            :clearable="false"
            :searchable="false"
            @option:selected="handleFilterSelected"
            @input="modality"
          />
        </b-form-group>

        <!-- Organizer -->
        <b-form-group>
          <label for="type">{{ $t("events.actions.organizer.title") }}:</label>
          <b-form-checkbox v-model="showCreated" switch />
        </b-form-group>
        <b-form-group v-if="event.description">
          <label
            class="mt-2"
            for="description"
          >{{ $t("form-create-item.description") }}:</label>
          <quill-editor v-model="event.description.en" />
        </b-form-group>
        <b-form-group
          :label="`${$t('calendar.meeting-form.start-date.label')}*  (${$t(
            'events.event-details.local-time'
          )})`"
        >
          <VueCtkDateTimePicker
            v-model="eventItem.startDate"
            :locale="locale"
            :label="$t('datepicker.select-label')"
            color="var(--primary)"
            format="YYYY-MM-DD HH:mm"
            no-button-now
            button-color="var(--primary)"
          />
        </b-form-group>
        <b-form-group
          :label="`${$t('calendar.meeting-form.end-date.label')}*  (${$t(
            'events.event-details.local-time'
          )})`"
        >
          <VueCtkDateTimePicker
            v-model="eventItem.endDate"
            :locale="locale"
            :label="$t('datepicker.select-label')"
            color="var(--primary)"
            format="YYYY-MM-DD HH:mm"
            no-button-now
            :behaviour="{
              time: {
                nearestIfDisabled: false,
              },
            }"
            button-color="var(--primary)"
            :min-date="eventItem.startDate"
          />
        </b-form-group>
        <div class="border rounded p-2">
          <p class="mb-50">
            {{ $t("form-create-item.image-select") }}
          </p>
          <file-preview
            v-if="thumbnailBG"
            :src="thumbnailBG"
            mime-type="image"
            cancelable
            @cancel="(thumbnailBG = null), (itemImage = null)"
          />
          <b-media
            v-else
            no-body
            vertical-align="center"
            class="d-flex align-items-center flex-column flex-md-row"
          >
            <b-media-body>
              <div class="d-inline-block d-flex align-items-center pl-2">
                <b-form-file
                  v-model="itemImage"
                  class="pointer"
                  :accept="FileTypes"
                  :placeholder="$t('form-create-item.image-placeholder')"
                  :browse-text="$t('form-create-item.image-button')"
                />
              </div>
            </b-media-body>
          </b-media>
        </div>
      </b-form>
    </b-modal>
    <share-modal
      v-model="isShareModalVisible"
      :can-share="canShare"
      :item-name="translatedName"
      model-type="event"
      type="events"
      :model-key="event.key"
    />
    <unshare-item-modal
      v-model="isUnshareModalVisible"
      model-type="event"
      @remove-item="deleteItem(event)"
      @modal-invisible="isUnshareModalVisible = false"
    />
  </b-modal>
</template>

<script>
import {
  EVENTS_STORE_MODULE_NAME,
  EVENTS_ACTIONS,
} from '@/views/apps/events/constants/events-store-constants';
import {
  BModal, BLink, BRow, BCol, BAlert,
} from 'bootstrap-vue';
import { translateTranslationTable } from '@core/libs/i18n/utils';
import ContentWithAnchors from '@core/components/string-utils/ContentWithAnchors.vue';
import EventPlaceholder from '@/assets/images/placeholders/light/general-placeholder.jpeg';
import SafeImg from '@core/components/safe-img/SafeImg.vue';
import { getImageResource } from '@/@core/utils/image-utils';
import AddToExternalCalendarModal from '@/views/apps/events/components/modals/AddToExternalCalendarModal.vue';
import LocationsMap from '@/views/apps/locations/components/LocationsMap.vue';
import EventSubscriptionButton from '@/views/event-detail/EventSubscriptionButton.vue';
import { EventType } from '@copernicsw/community-common';
import UnshareItemModal from '@/@core/components/modal/UnshareItemModal.vue';
import { quillEditor } from 'vue-quill-editor';
import FileTypes from '@/@core/constants/FileTypes';
import vSelect from 'vue-select';
import FilePreview from '@core/components/files/FilePreview.vue';
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue';
import Contact from '@core/components/contact/Contact.vue';
import { randomString } from '@/@core/utils/utils';
import { checkPermissions } from '@/@core/utils/roles-utils';
import ShareModal from '@/@core/components/modal/ShareModal.vue';
import { DateTime } from 'luxon';
import EventBriefInformation from './EventBriefInformation.vue';

export default {
  name: 'EventModal',
  components: {
    BModal,
    BLink,
    BRow,
    BCol,
    BAlert,
    ContentWithAnchors,
    EventBriefInformation,
    SafeImg,
    AddToExternalCalendarModal,
    LocationsMap,
    EventSubscriptionButton,
    UnshareItemModal,
    quillEditor,
    vSelect,
    FilePreview,
    ToastificationContentVue,
    Contact,
    ShareModal,
  },
  model: {
    prop: 'visible',
    event: 'update:visible',
  },
  props: {
    visible: Boolean,
    event: {
      type: Object,
      required: true,
    },
    isFromEvent: {
      type: Boolean,
      default: false,
    },
    community: {
      type: Object,
    },
    isMembership: Boolean,
  },
  data() {
    return {
      isLoading: true,
      isAddingToExternalCalendar: false,
      isMapVisible: false,
      isUnshareModalVisible: false,
      isEditModalVisible: false,
      isEditContactModalVisible: false,
      altKey: 'Agenda-unique',
      thumbnailBG: this.event.thumbnailURL,
      imageSrc: null,
      itemImage: null,
      selectedOption: '',
      isShareModalVisible: false,
      showCreated: this.event.showCreated,
      eventItem: {
        startDate: '',
        endDate: '',
      },
    };
  },
  computed: {
    isStaff() {
      return this.collective.isStaff;
    },
    collective() {
      return this.$store.state.collective.collective;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    currentCollectiveKey() {
      return this.$store.getters.currentCollective.key;
    },
    timezone() {
      return this.collective.timezone;
    },
    isNotDisabled() {
      return this.event.endDate && this.event.startDate && this.event.title;
    },
    FileTypes() {
      return FileTypes;
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    canShare() {
      return (
        this.isStaff
        || checkPermissions(
          'share',
          'event',
          this.loggedMemberRoles,
          this.collective,
        )
      );
    },

    typeOptions() {
      if (this.event.modality === 'online') {
        this.selectedOption = this.$t('events.event-details.online-event');
      } else if (this.event.modality === 'on-site') {
        this.selectedOption = this.$t('events.event-details.on-site-event');
      } else if (this.event.modality === 'hybrid') {
        this.selectedOption = this.$t('events.event-details.hybrid');
      }
      return [
        this.$t('events.event-details.online-event'),
        this.$t('events.event-details.on-site-event'),
        this.$t('events.event-details.hybrid'),
      ];
    },
    isVisible: {
      get() {
        return this.visible;
      },
      set(isVisible) {
        this.$emit('update:visible', isVisible);
      },
    },
    translatedDescription() {
      return translateTranslationTable(
        this.$store.state.locale.currentLocale,
        this.event?.description,
      );
    },
    translatedName() {
      return translateTranslationTable(
        this.$store.state.locale.currentLocale,
        this.event?.title,
      );
    },
    thumbnailSrc() {
      return getImageResource(this.event.thumbnailURL);
    },
    isOver() {
      const endDate = this.event.endDate || this.$moment(this.event.startDate).endOf('day');
      return this.$moment(Date.now()).isAfter(endDate);
    },
  },
  watch: {
    isVisible(value) {
      if (value) {
        this.initialValue();
      }
    },
  },
  watch: {
    event() {
      this.calculateDates();
    },
  },
  created() {
    this.calculateDates();
  },
  setup() {
    return { EventPlaceholder };
  },
  methods: {
    calculateDates() {
      this.eventItem.startDate = this.event.startDate ? new Date(this.event.startDate).toLocaleString()
        : null;
      this.eventItem.endDate = this.event.endDate ? new Date(this.event.endDate).toLocaleString()
        : null;
    },
    initialValue() {
      this.isLoading = true;
      this.event = this.event;
      if (this.event.title[this.locale] == null) {
        this.event.title[this.locale] = Object.values(this.event.title)[0];
      }
      if (this.event.description && this.event.description[this.locale] == null) {
        this.event.description[this.locale] = Object.values(
          this.event.description,
        )[0];
      }

      this.altKey = randomString(20);
      this.isLoading = false;
    },
    translate(data) {
      return translateTranslationTable(
        this.$store.state.locale.currentLocale,
        data,
      );
    },
    deleteItem(event) {
      try {
        const res = this.$store.dispatch('deleteEvent', {
          item: {
            itemType: 'event',
            requestConfig: { key: event.key },
          },
        });
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t('unshare.toast-messages.success', { item: 'Event' }),
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        });
        this.$emit('edited-event');

        this.isUnshareModalVisible = false;
      } catch (error) {
        console.error(error);
      }
    },
    unixTimestamp(date) {
      const newDate = new Date(date);
      return Math.floor(newDate.getTime());
    },
    async editEvent(poweredByKey, poweredByType) {
      const startDate = this.eventItem.startDate ? this.unixTimestamp(new Date(this.eventItem.startDate).toUTCString()) : null;
      const endDate = this.eventItem.endDate ? this.unixTimestamp(new Date(this.eventItem.endDate).toUTCString()) : null;

      const event = {
        key: this.event.key,
        id: this.event.key,
        title: this.event.title,
        description: this.event.description,
        location: [],
        startDate,
        endDate,
        showCreated: this.showCreated,
        modality: this.event.modality ?? 1,
        thumbnailURL: this.itemImage,
        deleteImage:
          !!(this.thumbnailBG == null && this.itemImage == null),
        poweredByKey,
        poweredByType,
      };
      if (event.deleteImage) {
        this.itemImage = null;
      }

      try {
        this.$store.dispatch(EVENTS_ACTIONS.editEvent, {
          event,
        });

        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t('events.actions.success-edit'),
            icon: 'CheckIcon',
            variant: 'success',
          },
        });
        this.event.startDate = startDate;
        this.event.endDate = endDate;
        this.$emit('edited-event');
        this.currentPage = 0;
      } catch (error) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t('error-message.general-error'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
        console.log(error);
      }
    },
    async handleFilterSelected() {
      this.currentPage = 0;
      this.events = [];
      /*       await this.handleLoadNextPage(); */
    },
    modality() {
      this.$emit('update-event', this.event);
      switch (this.selectedOption) {
        case this.typeOptions[1]:
          this.event.modality = 0;
          break;
        case this.typeOptions[2]:
          this.event.modality = 2;
          break;
        case this.typeOptions[0]:
          this.event.modality = 1;
          break;
        default: this.event.modality = 1;
      }
    },
    handleAddingToExternalCalendar(isAdding) {
      this.isAddingToExternalCalendar = isAdding;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-include";
.trash-icon {
  margin-right: -12px;
  margin-top: -2px;
}
.content-container {
  width: 97%;
}
.event-title {
  text-transform: uppercase;
}
.event-img {
  border-radius: 10px;
  max-height: 300px;
  object-fit: cover;
  @include media-breakpoint-up(lg) {
    max-height: 160px;
  }
}
.locations-map {
  height: 100px;
}
.modal-description {
  img {
    width: 300px !important;
  }
}
</style>
